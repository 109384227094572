<template>
  <div class="Desloratadine-game">
    <div class="game-stage" v-show="!result.win && !result.lose">
      <div class="game-bar" ref="gameBare">
        <div class="game-bar__title">
          Завдання — зловити алергени!<br/>
          Зловіть кожен та не дайте їм дістатися до бар'єру.
        </div>

        <div class="game-bar__line">
          <div class="game-bar__timer-section">
            <svg
                :class="[{ red: time <= 5 }]"
                viewBox="0 0 28 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                  cx="14"
                  cy="18.3333"
                  r="13"
                  stroke="#00A03B"
                  stroke-width="2"
              />
              <path d="M17.7913 1H10.208" stroke="#00A03B" stroke-width="2"/>
              <path
                  d="M14 31.3333L14 28.0833"
                  stroke="#00A03B"
                  stroke-width="2"
              />
              <path
                  d="M14 8.58331L14 5.33331"
                  stroke="#00A03B"
                  stroke-width="2"
              />
              <path
                  d="M1 18.3333L4.25 18.3333"
                  stroke="#00A03B"
                  stroke-width="2"
              />
              <path
                  d="M23.75 18.3333L27 18.3333"
                  stroke="#00A03B"
                  stroke-width="2"
              />
              <path
                  d="M4.80737 9.14093L7.10547 11.439"
                  stroke="#00A03B"
                  stroke-width="2"
              />
              <path
                  d="M20.8943 25.2277L23.1924 27.5258"
                  stroke="#00A03B"
                  stroke-width="2"
              />
              <path
                  d="M23.1926 9.14093L20.8945 11.439"
                  stroke="#00A03B"
                  stroke-width="2"
              />
              <path
                  d="M7.10571 25.2277L4.80762 27.5258"
                  stroke="#00A03B"
                  stroke-width="2"
              />
              <path
                  d="M14.0003 18.7246L18.6045 10.75"
                  stroke="#00A03B"
                  stroke-width="2"
              />
            </svg>

            <timer :class="[{ red: time <= 5 }]" :time="prettyTime"></timer>
          </div>

          <div class="game-bar__health-wrapper">
            <svg
                :class="{ active: hp >= 3 }"
                width="38"
                height="33"
                viewBox="0 0 38 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M16.6032 2.86942C12.805 -0.956476 6.64686 -0.956474 2.84865 2.86943C-0.949555 6.69533 -0.94955 12.8983 2.84866 16.7242L5.24546 19.1385L5.23886 19.1452L18.9934 33L19 32.9933L19.0066 33L32.7611 19.1452L32.7545 19.1385L35.1513 16.7242C38.9496 12.8983 38.9496 6.69533 35.1513 2.86943C31.3531 -0.956474 25.195 -0.956476 21.3968 2.86942L19 5.28371L16.6032 2.86942Z"
                  fill="#C40000"
              />
            </svg>

            <svg
                :class="{ active: hp >= 2 }"
                width="38"
                height="33"
                viewBox="0 0 38 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M16.6032 2.86942C12.805 -0.956476 6.64686 -0.956474 2.84865 2.86943C-0.949555 6.69533 -0.94955 12.8983 2.84866 16.7242L5.24546 19.1385L5.23886 19.1452L18.9934 33L19 32.9933L19.0066 33L32.7611 19.1452L32.7545 19.1385L35.1513 16.7242C38.9496 12.8983 38.9496 6.69533 35.1513 2.86943C31.3531 -0.956474 25.195 -0.956476 21.3968 2.86942L19 5.28371L16.6032 2.86942Z"
                  fill="#C40000"
              />
            </svg>

            <svg
                :class="{ active: hp >= 1 }"
                width="38"
                height="33"
                viewBox="0 0 38 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M16.6032 2.86942C12.805 -0.956476 6.64686 -0.956474 2.84865 2.86943C-0.949555 6.69533 -0.94955 12.8983 2.84866 16.7242L5.24546 19.1385L5.23886 19.1452L18.9934 33L19 32.9933L19.0066 33L32.7611 19.1452L32.7545 19.1385L35.1513 16.7242C38.9496 12.8983 38.9496 6.69533 35.1513 2.86943C31.3531 -0.956474 25.195 -0.956476 21.3968 2.86942L19 5.28371L16.6032 2.86942Z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="game">
        <div ref="gameWrapper" class="game__action" :style="heightContent">
          <div
              class="game__error-block"
              v-bind:class="[{ errorStyle: error }]"
          ></div>

          <div class="game__prepear" v-if="prepear">
            <div v-if="prepearStage === 1" class="small">Приготуйтеся!</div>
            <div v-if="prepearStage === 2" class="big">3</div>
            <div v-if="prepearStage === 3" class="big">2</div>
            <div v-if="prepearStage === 4" class="big">1</div>
            <div v-if="prepearStage === 5" class="small">Старт!</div>
          </div>

          <div
              @click="key.result = true"
              :style="[
              {
                left: key.position,
                backgroundImage:
                  'url(' +
                  require(`../assets/img/game-desloratadine/${key.img}`) +
                  ')',
              },
              drop,
            ]"
              v-bind:class="[
              { active: key.time >= time },
              { tap: key.result },
              { tap: key.result === true },
            ]"
              class="game__drop-item"
              v-for="(key, value) in items"
              :key="value"
          >
            <!-- <img
              class="item"
              :src="require(`../assets/img/game-desloratadine/${key.img}`)"
            /> -->
            <img
                v-show="key.result === false"
                class="error"
                src="../assets/img/game-desloratadine/error.png"
                alt=""
            />
          </div>

          <div ref="hitZone" class="game__hit-zone">
            <img
                class="mob"
                src="../assets/img/game-desloratadine/bg-bottom.png"
                alt=""
            />
            <img
                class="desk"
                src="../assets/img/game-desloratadine/bg-bottom-desk.png"
                alt=""
            />
          </div>
        </div>

        <div class="game__btn-wrapper" ref="btnWrapper">
          <div class="game__grey-text">
            Натискайте на падаючі алергени, <br/>щоб зловити їх
          </div>
        </div>
      </div>
    </div>

    <div class="win" v-show="result.win">
      <div class="win__green-block blue stage1">
        <p class="win__text white">
          <span>Дезлоратадин-Тева!</span><br/>Дякую, тепер можна і в парк
        </p>

        <div class="win__arrow-down blue"></div>
      </div>

      <img
          class="win__img-circle"
          src="../assets/img/game-desloratadine/win.png"
          alt=""
      />

      <div class="win__text-center">
        Прекрасна робота!<br/>
        Ми і не сумнівались, що Ви впораєтесь із цим завданням
      </div>
    </div>

    <div class="lose" v-show="result.lose">
      <div class="lose__red-block stage1">
        <p class="lose__text desk white">
          <span>Трішки більше уваги і все вдасться!</span><br/>Нехай алергени
          зустрінуться з Вашою спритністю, спробуйте ще раз<br/>
        </p>

        <div class="lose__arrow-down"></div>
      </div>

      <img
          class="lose__img-circle"
          src="../assets/img/game-desloratadine/lose.png"
          alt=""
      />

      <div class="lose__repeat hover_type6" @click="reset()">
        <p>
          Ще раз!
        </p>
      </div>
    </div>

    <div v-show="result.lose || result.win" class="notes-wrapper">
      <span>
        Інформація про лікарський засіб. РП №UA/18739/01/01. Для професійної
        діяльності медичних та фармацевтичних працівників.
      </span>

      <span>
        Дезлоратадин-Тева (1 таблетка, вкрита плівковою оболонкою, містить
        дезлоратадину 5 мг). Характеристики: блакитні круглі, діаметром 6 мм
        двоопуклі таблетки, вкриті плівковою оболонкою, з тисненням «LT» з
        одного боку. Лікувальні властивості: усунення симптомів, пов’язаних з
        алергічним ринітом, кропив’янкою. Найбільш частими побічними реакціями
        є: головний біль, сухість у роті, втомлюваність. Повний перелік побічних
        реакцій міститься в інструкції.
      </span>

      <span>
        Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
        поверх 9; 0-800-502-284
      </span>

      <span>CBG-UA-00896. Термін дії матеріалу – 10.2024.</span>
    </div>
  </div>
</template>

<script>
import Timer from "./Timer.vue";

export default {
  name: "Desloratadine-game",
  components: {
    timer: Timer,
  },
  data: () => ({
    minutes: 0,
    secondes: 0,
    time: 20,
    timer: null,
    hp: 3,
    items: [

      {
        time: 19,
        img: "allergen3.png",
        result: null,
        position: "80%",
      },
      {
        time: 18,
        img: "allergen4.png",
        result: null,
        position: "20%",
      },
      {
        time: 17,
        img: "allergen1.png",
        result: null,
        position: "50%",
      },
      {
        time: 16,
        img: "allergen2.png",
        result: null,
        position: "64%",
      },
      {
        time: 15,
        img: "allergen3.png",
        result: null,
        position: "12%",
      },
      {
        time: 14,
        img: "allergen4.png",
        result: null,
        position: "70%",
      },
      {
        time: 13,
        img: "allergen1.png",
        result: null,
        position: "24%",
      },
      {
        time: 12,
        img: "allergen2.png",
        result: null,
        position: "77%",
      },
      {
        time: 11,
        img: "allergen3.png",
        result: null,
        position: "45%",
      },
      {
        time: 10,
        img: "allergen4.png",
        result: null,
        position: "12%",
      },
      {
        time: 9,
        img: "allergen1.png",
        result: null,
        position: "66%",
      },
      {
        time: 8,
        img: "allergen2.png",
        result: null,
        position: "12%",
      },
      {
        time: 7,
        img: "allergen3.png",
        result: null,
        position: "50%",
      },
      {
        time: 6,
        img: "allergen4.png",
        result: null,
        position: "80%",
      },
      {
        time: 5,
        img: "allergen1.png",
        result: null,
        position: "14%",
      },
      {
        time: 4,
        img: "allergen2.png",
        result: null,
        position: "72%",
      },
      {
        time: 3,
        img: "allergen3.png",
        result: null,
        position: "12%",
      },
      {
        time: 2,
        img: "allergen4.png",
        result: null,
        position: "78%",
      },
      {
        time: 1,
        img: "allergen1.png",
        result: null,
        position: "43%",
      },
    ],

    hpTrigger: false,

    prepear: true,
    prepearStage: 1,
    gameHeight: "0px",
    dropHeight: "0px",

    error: false,

    result: {
      win: false,
      lose: false,
    },
  }),

  watch: {
    hpTrigger: function (val) {
      if (val) {
        this.hp = this.hp - 1;
      }
    },

    time: function (val) {
      this.items.forEach((el) => {

        if (el.result === null && val <= el.time - 2) {
          this.error = true;
          el.result = false;
          this.hpTrigger = true;
        }

        let checkWin = this.items.some((el) => el.result === null);

        if (!checkWin) {
          setTimeout(() => {
            this.result.win = true;
            window.scrollTo(0, 0);
          }, 500);
        }
      });

      if (val === 0) {
        if (this.hp > 0) {
          setTimeout(() => {
            this.result.win = true;
            window.scrollTo(0, 0);
          }, 500);
        }
      }
    },

    hp: function (val) {
      this.hpTrigger = false;
      setTimeout(() => {
        this.error = false;
      }, 500);

      if (val === 0) {
        setTimeout(() => {
          this.result.lose = true;
        }, 1000);
        clearInterval(this.timer);
        window.scrollTo(0, 0);
      }
    },
  },

  computed: {
    heightContent() {
      return {
        "--height": this.gameHeight,
      };
    },
    drop() {
      return {
        "--heightDrop": this.dropHeight,
      };
    },
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },
  },

  mounted() {
    this.gameHeight =
        this.$refs.gameBare.getBoundingClientRect().height +
        this.$refs.btnWrapper.getBoundingClientRect().height +
        "px";

    setTimeout(() => {
      this.dropHeight =
          this.$refs.gameWrapper.getBoundingClientRect().height + "px";
    });
    setTimeout(() => {
      this.prepearStage = 2;
    }, 1000);
    setTimeout(() => {
      this.prepearStage = 3;
    }, 2000);
    setTimeout(() => {
      this.prepearStage = 4;
    }, 3000);
    setTimeout(() => {
      this.prepearStage = 5;
    }, 4000);

    setTimeout(() => {
      this.start();
      this.prepear = false;
    }, 5000);
  },

  methods: {
    start() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            clearInterval(this.timer);
            // this.reset()
          }
        }, 1000);
      }
    },

    reset() {
      this.time = 20;
      this.result.lose = false;
      this.result.win = false;
      this.hp = 3;
      this.error = false;

      this.prepear = true;
      this.prepearStage = 1;

      this.timer = null;

      this.items.forEach((el) => {
        el.result = null;
      });

      setTimeout(() => {
        this.prepearStage = 2;
      }, 1000);
      setTimeout(() => {
        this.prepearStage = 3;
      }, 2000);
      setTimeout(() => {
        this.prepearStage = 4;
      }, 3000);
      setTimeout(() => {
        this.prepearStage = 5;
      }, 4000);

      setTimeout(() => {
        this.start();
        this.prepear = false;
      }, 5000);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.Desloratadine-game {
  max-width: 1240px;
  width: 100%;

  display: flex;
  flex-direction: column;

  min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)));
  height: 100%;
  margin: 0 auto;

  .game-stage {
    padding: 40px 20px 40px 20px;
  }

  .game-bar {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    max-width: 650px;
    margin: 0 auto 16px auto;

    &__line {
      display: flex;
      justify-content: space-between;
    }

    &__timer-section {
      display: flex;
      align-items: center;

      svg {
        max-width: 28px;
        width: 100%;
        display: block;
        margin-right: 10px;

        &.red {
          circle {
            stroke: #c40000;
          }

          path {
            stroke: #c40000;
          }
        }
      }
    }

    &__health-wrapper {
      display: flex;
      align-items: center;

      svg {
        display: block;
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }

        path {
          fill: $grey;
        }

        &.active {
          path {
            fill: #c40000;
          }
        }
      }
    }

    &__title {
      font-family: $M;
      font-size: 20px;
      line-height: 120%;
      color: $green;
      text-align: center;
      margin-bottom: 18px;
    }
  }

  .game {
    max-width: 650px;

    margin: 0 auto;

    width: 100%;
    position: relative;

    &__error-block {
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
              97.79% 97.79% at 50% 50%,
              #ffffff 0%,
              #fac5c5 54.69%,
              #c40000 100%
      );
      opacity: 0;

      &.errorStyle {
        opacity: 1;
      }
    }

    &__prepear {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: -25%;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        color: $green;
        font-family: $EB;
      }

      .small {
        font-size: 24px;
        line-height: 120%;
      }

      .big {
        font-size: 48px;
        line-height: 120%;
      }
    }

    &__grey-text {
      text-align: center;
      color: $grey;
      font-family: $M;
      opacity: 0.4;
      margin-top: 25px;
    }

    &__action {
      width: 100%;
      min-height: 400px;

      //min-height: calc(
      //  (var(--vh, 1vh) * 100) - (var(--height_header)) - (var(--height)) -
      //    120px
      //);

      background: #ffffff;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      position: relative;

      //.error-bg {
      //  position: absolute;
      //  left: 0;
      //  right: 0;
      //  bottom: 0;
      //  top: 0;
      //  width: 100%;
      //  height: 100%;
      //  border-radius: 20px;
      //
      //  z-index: -1;
      //
      //}
    }

    &__hit-zone {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      border-radius: 0 0 20px 20px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: auto;

        &.mob {
          display: none;
        }
      }
    }

    &__drop-item {
      position: absolute;

      transform: translateY(-100%);
      cursor: pointer;

      user-select: none;
      height: 150px;
      width: 68px;
      background-color: transparent;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;

      // display: flex;
      // align-items: center;

      &.tap {
        display: none;
      }

      &.active {
        transform: translateY(calc(var(--heightDrop) + 20px));
        transition: transform 2.9s linear;
      }

      .item {
        max-width: 68px;
        width: 100%;
        height: auto;
        display: block;
        overflow: hidden;

        &:active {
          filter: blur(5px);
        }
      }

      .error {
        max-width: 89px;
        width: 100%;
        height: auto;
        display: block;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .lose {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;

    padding: 0 30px 30px 30px;

    margin: 0 auto;
    margin-top: 40px;

    &__red-block {
      background: #c40000;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: calc(100% - 30px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      span {
        font-family: $EB;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid #c40000;
    }

    &__img-circle {
      max-width: 309px;
      width: 100%;
      height: auto;
      display: block;
      margin: 50px auto 0 auto;
      border-radius: 50%;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
    }

    &__repeat {
      margin: 0 auto;
      border: 2px solid $green;
      border-radius: 35px;
      background: white;
      margin-top: 48px;
      padding: 14px 15px;

      max-width: 230px;
      width: 100%;

      cursor: pointer;

      p {
        color: $green;

        font-family: $EB;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .win {
    position: relative;
    padding: 40px 30px 0 30px;

    max-width: 650px;
    height: 100%;
    width: 100%;
    margin: 0 auto 0 auto;

    display: flex;
    flex-direction: column;

    &__green-block {
      background: $blue;
      position: absolute;
      left: 0;
      right: 0;
      max-width: calc(100% - 30px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;

      &.mob {
        display: none;
      }

      span {
        font-family: $EB;
      }
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid $blue;
    }

    &__img-circle {
      max-width: 309px;
      width: 100%;
      height: auto;
      display: block;
      margin: 35px auto 0 auto;

      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
      border-radius: 50%;
    }

    &__text-center {
      text-align: center;
      margin-top: 40px;
      color: $green;
      font-size: 24px;
      line-height: 120%;
      font-family: $B;
    }

    &__info-text-btn {
      margin: auto 0 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info-text {
      max-width: 474px;
      width: 100%;
      margin-right: 15px;

      p {
        font-size: 16px;
        line-height: 120%;
        color: $grey;
        font-family: $M;
        vertical-align: center;
        position: relative;

        sup {
          font-size: 80%;
          line-height: 0;
          vertical-align: top;
        }
      }

      span {
        font-family: $EB;
      }
    }

    &__info-btn {
      margin-left: 15px;
      cursor: pointer;
    }

    &__popup {
      bottom: 40px;
      position: absolute;
      width: calc(100% - 20px);
      background: white;
      padding: 20px 20px 40px 20px;
      left: 50%;
      transform: translateX(-50%);

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &__popup--close-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      svg {
        display: block;
        cursor: pointer;
      }
    }

    &__popup--text {
      font-size: 10px;
      line-height: 120%;
      font-family: $M;
      color: $grey;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .Desloratadine-game {
    .game-stage {
      padding: 20px 20px 20px 20px;
    }

    .lose {
      margin-top: 20px;
      padding-bottom: 0;

      &__red-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;

        top: 0;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 360px;
      }

      &__repeat {
        p {
        }
      }
    }

    .win {
      padding-top: 20px;
      padding-bottom: 0;

      &__green-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;

        &.mob {
          display: block;
        }

        &.desk {
          display: none;
        }

        span {
        }
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 360px;
        margin-top: 30px;
      }

      &__text-center {
        font-size: 16px;
        line-height: 120%;
        margin-top: 20px;
      }

      &__info-text-btn {
        margin-bottom: 0;
      }

      &__info-text {
        p {
          font-size: 12px;
          line-height: 120%;

          sup {
          }
        }

        span {
        }
      }

      &__info-btn {
      }

      &__popup {
        padding: 18px 20px 43px 20px;
        bottom: 10px;
      }

      &__popup--close-btn {
        margin-bottom: 14px;

        svg {
        }
      }

      &__popup--text {
        font-size: 8px;
        line-height: 8px;
      }
    }

    .notes-wrapper {
    }

    .game-bar {
      margin-bottom: 13px;

      &__timer-section {
        svg {
          max-width: 26px;
        }
      }

      &__health-wrapper {
        svg {
          &:last-child {
          }

          path {
          }
        }
      }

      &__title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 14px;
      }
    }

    .game {
      &__action {
        //max-height: calc(
        //  (var(--vh, 1vh) * 100) - (var(--height_header)) - (var(--height)) -
        //    80px
        //);

        min-height: 340px;
      }

      &__grey-text {
        font-size: 14px;
        line-height: 120%;
        margin-top: 18px;
      }

      &__hit-zone {
        img {
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .Desloratadine-game {
    .game {
      &__hit-zone {
        img {
          &.desk {
            display: none;
          }

          &.mob {
            display: block;
          }
        }
      }

      &__drop-item {
        &.active {
          transition: transform 3.2s linear;
        }
      }
    }
  }
}

//@media screen and (min-height: 775px) and (min-width: 600px) and (max-height: 900px) {
//  .Desloratadine-game {
//    .game {
//      &__drop-item {
//        &.active {
//          transition: transform 5.6s linear;
//        }
//      }
//    }
//  }
//}
//
//@media screen and (min-height: 901px) and (min-width: 600px) {
//  .Desloratadine-game {
//    .game {
//      &__drop-item {
//        &.active {
//          transition: transform 5s linear;
//        }
//      }
//    }
//  }
//}
</style>
