<template>
  <div class="Desloratadine">

    <div class="game-wrapper"
         :class="[{ stage1: stage ===1  }, { stage2: stage ===2  }, { stage3: stage ===3  }, { stage4: stage ===4  },{ stage5: stage ===5  },{ stage6: stage ===6  },{ stage7: stage ===7  }]">

      <div class="game-wrapper__stage " v-show="stage === 1">

        <div class="game-wrapper__stage--white-block stage1">
          <p class="game-wrapper__stage--text big green">
            <span>АПЧХИ!<br>Аа-а-апчхи…</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-desloratadine/stage1-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(2)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 2">

        <div class="game-wrapper__stage--img-circle  stage2">
          <img src="../../assets/img/game-desloratadine/stage2-circle.png" alt="">

          <div class="game-wrapper__stage--white-block bottom stage2">
            <p class="game-wrapper__stage--text blue">
              <span>Будьте здорові!<br>Чим можу Вам допомогти?</span>
            </p>
          </div>
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(3)">
          <p>Далі</p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 3">

        <div class="game-wrapper__stage--white-block stage3">
          <p class="game-wrapper__stage--text green ">
            <span>Чхання, тече з носа та очі сльозяться…<br>Напевно, знову алергія!</span></p>


          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-desloratadine/stage3-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(4)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 4">

        <div class="game-wrapper__stage--img-circle stage4">
          <img src="../../assets/img/game-desloratadine/stage3-circle.png" alt="">

          <div class="game-wrapper__stage--white-block  bottom stage4">
            <p class="game-wrapper__stage--text blue">
              <span>Зустріч з пухнастиком чи запах квітів часто можуть довести до сліз людей з алергією</span>
            </p>
          </div>
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(5)">
          <p>Далі</p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 5">

        <div class="game-wrapper__stage--white-block stage5">
          <p class="game-wrapper__stage--text green ">
            <span>Порадьте дієвий засіб, щоб швидк… АПЧХИ!</span></p>


          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-desloratadine/stage2-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(6)">
          <p>
            Допомогти клієнту
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 6">

        <div class="game-wrapper__stage--white-block popup custom-p">

          <div class="game-wrapper__stage--top-text">
            До 30-40% населення світу страждає на ті чи інші прояви алергії<sup>1</sup> <span
              style="white-space: nowrap;">Дезлоратадин-Тева&nbsp;–</span> неседативний
            антигістамінний засіб для усунення симптомів алергічного риніту та кропивʼянки<sup>2</sup>.
          </div>

          <div class="game-wrapper__stage--bottom-text desk">
            Ми підготували набір параметрів-підказок, які мають наштовхнути Вас на відгадку про те, що виписав лікар.
            Впевнені, що Ви без жодних проблем визначите лікарський засіб. <span>Впораєтесь?</span>
          </div>

          <div class="game-wrapper__stage--bottom-text mob">
            Чи зможуть алергени подолати Вашу спритність?<br>
            Саме час перевірити!
          </div>

          <div class="game-wrapper__stage--btn-next-stage hover_type6 auto-m" @click="nextStage(7)">
            <p>Вперед!</p>
          </div>
        </div>

      </div>

      <DesloratadineGame v-if="stage === 7"></DesloratadineGame>

    </div>

    <div v-show="stage !== 7" class="notes-wrapper" :class="{ active:  stage === 6  }">

      <span v-if="stage === 6">1. Pawankar R. et al. World allergy organization (WAO) White Book on Allergy: Update 2013.</span>
      <span v-if="stage === 6">2. Інструкція для медичного застосування лікарського засобу ДЕЗЛОРАТАДИН-ТЕВА, таблетки, вкриті плівковою оболонкою, по 5 мг. РП №UA/18739/01/01. Наказ МОЗ України №905 від 11.05.2021.</span>

      <span>Інформація про лікарський засіб. РП №UA/18739/01/01. Для професійної діяльності медичних та фармацевтичних працівників.
</span>

      <span>Дезлоратадин-Тева (1 таблетка, вкрита плівковою оболонкою, містить дезлоратадину 5 мг). Характеристики: блакитні круглі, діаметром 6 мм двоопуклі таблетки, вкриті плівковою оболонкою, з тисненням «LT» з одного боку. Лікувальні властивості: усунення симптомів, пов’язаних з алергічним ринітом, кропив’янкою. Найбільш частими побічними реакціями є: головний біль, сухість у роті, втомлюваність.
Повний перелік побічних реакцій міститься в інструкції.</span>

      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284
</span>

      <span>CBG-UA-00896. Термін дії матеріалу – 10.2024.</span>
    </div>


    <img v-if="stage===1 || stage===2|| stage===3|| stage===4|| stage===5|| stage===7" class="bg"
         src="../../assets/img/game-desloratadine/bg.png"
         alt="">

    <img v-else class="bg" src="../../assets/img/game-clopidogrel/bg-type-2.jpg" alt="">

  </div>
</template>

<script>
import DesloratadineGame from "../../components/Desloratadine-game.vue";

export default {
  name: "Desloratadine",
  data: () => ({
    stage: 1,
  }),
  components: {
    DesloratadineGame,
  },
  methods: {
    nextStage(stage) {
      this.stage = stage;
      window.scrollTo(0, 0)

    }
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";

.Desloratadine {
  position: relative;
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_header));
  height: 100%;

  display: flex;
  flex-direction: column;

  .bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .game-wrapper {
    height: 100%;
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;

    padding: 40px 20px;

    &.stage7 {
      padding: 0;
    }

    &__stage {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;

      &--white-block {
        max-width: 610px;
        width: 100%;


        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 20px;
        border-radius: 14px;
        height: 110px;

        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;

        &.bottom {
          top: initial;
          bottom: 0;
        }

        &.custom-p {
          padding: 0;
          padding-bottom: 35px;
        }

        &.popup {
          position: relative;
          top: 0;
          display: flex;
          flex-direction: column;
          border-radius: 0;
          height: auto;

          img {
            max-width: 394px;
            margin: 0 auto;
            width: 100%;
            height: auto;
            display: block;
          }
        }

        &.stage3 {
          img {
            max-width: 280px;
            width: 100%;
          }
        }
      }

      &--text {
        font-size: 20px;
        line-height: 120%;
        font-family: $M;
        text-align: center;

        max-width: 610px;
        width: 100%;

        &.big {
          font-size: 32px;
          line-height: 120%;

        }

        span {
          font-family: $EB;
        }

        &.mobile {
          display: none;
        }

        &.green {
          color: $green;
        }

        &.blue {
          color: $blue;
        }
      }

      &--img-circle {
        position: relative;
        max-width: 100%;
        width: 100%;
        margin-top: 45px;


        img {
          margin: 0 auto;

          position: relative;
          z-index: 1;

          max-width: 358px;
          width: 100%;
          height: auto;

          display: block;

          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
          border-radius: 50%;

          @media screen and (max-width: 425px) {
            max-width: 100%;
          }
        }
      }

      &--btn-next-stage {
        border: 2px solid $green;
        background: white;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;

        margin-top: 40px;
        padding: 14px 15px;

        max-width: 230px;
        width: 100%;

        cursor: pointer;

        &.small-m {
          margin-top: 30px;
        }

        &.small-p {
          padding-top: 2px;
          padding-bottom: 3px;

        }

        &.auto-m {
          margin: auto 0 0 0;
        }

        p {
          color: $green;

          font-family: $EB;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
        }
      }

      &--popup-text {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        line-height: 120%;
        font-family: $M;
        color: $grey;

        padding: 0 20px;

        span {
          font-family: $EB;
          display: block;
          margin-top: 12px;
        }
      }

      &--arrow-down {
        position: absolute;
        bottom: -9px;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-top: 10px solid white;
      }

      &--top-text {
        background: $teal;
        padding: 80px 30px 80px 30px;
        text-align: center;

        font-family: $M;
        font-size: 24px;
        line-height: 120%;
        color: white;

        sup {
          font-size: 60%;
          line-height: 0;
          vertical-align: top;
        }
      }

      &--bottom-text {
        color: #424242;
        font-size: 16px;
        line-height: 120%;

        font-family: $M;

        padding: 60px 30px 30px 30px;
        text-align: center;

        &.mob {
          display: none;
        }

        span {
          display: block;
          margin-top: 10px;
          font-family: $B;
        }
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }


    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .Desloratadine {

    padding-top: 0;

    .bg {

    }

    .game-wrapper {
      padding: 20px 20px 0 20px;

      &__stage {

        &--white-block {
          height: 90px;
          padding: 10px;

          &.bottom {
            height: 100px;
          }


          &.stage4, &.stage2 {
            bottom: -15px;
          }

          &.custom-p {
          }

          &.popup {
            height: auto;
            padding-bottom: 17px;

            img {
              max-width: 236px;
            }
          }
        }

        &--text {
          font-size: 18px;
          line-height: 20px;

          &.big {
            font-size: 18px;
            line-height: 20px;
          }

          &.mobile {
            display: block;
          }

          &.desk {
            display: none;
          }
        }

        &--img-circle {
          margin-top: 40px;
          padding-left: 10px;
          padding-right: 10px;

          &.stage4 {
          }
        }

        &--btn-next-stage {

          margin-top: 25px;
          height: 50px;

          &.small-p {

          }

          &.auto-m {
          }

          p {
            font-size: 16px;
            line-height: 22px;

          }
        }

        &--popup-text {

          span {
            margin-top: 6px;
          }
        }

        &--arrow-down {

        }

        &--top-text {
          font-size: 16px;
          line-height: 120%;
          padding: 25px 20px;
        }

        &--bottom-text {
          font-size: 14px;
          line-height: 120%;
          padding: 41px 20px 45px 20px;

          &.mob {
            display: block;
          }

          &.desk {
            display: none;
          }
        }
      }
    }

    .notes-wrapper {

      padding-top: 23px;
      font-size: 8px;
      line-height: 8px;
      padding-left: 10px;
      padding-right: 10px;

    }
  }
}
</style>